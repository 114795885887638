import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './whitepaper-loading.css'

const WhitepaperLoading = (props) => {
  return (
    <div className="whitepaper-loading-container1">
      <Helmet>
        <title>OfficeX Whitepaper</title>
        <meta
          name="description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. For Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever"
        />
        <meta property="og:title" content="OfficeX Whitepaper" />
        <meta
          property="og:description"
          content="OfficeX Free Unlimited Workspace - Documents, Spreadsheets, Presentations &amp; Cloud Storage. Made for Freedom Loving Entrepreneurs. Private &amp; Secure. 100% Free Forever."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd80591-3bea-4d98-908e-93babf010021/4929c1c1-f2db-4ea6-895e-739a57049a5f?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <h1 className="whitepaper-loading-text1">
        <span>OfficeX</span>
        <br></br>
        <span>Whitepaper</span>
        <br></br>
      </h1>
      <h2 className="whitepaper-loading-text6">Loading...</h2>
      <div>
        <div className="whitepaper-loading-container3">
          <Script
            html={`
<script>
const destination = "https://docs.google.com/document/d/1J565dlAKY6dNPBZbXnxUezsTJGQBDKcO6vuklZ94BC0/edit?usp=sharing"
window.location.href = destination;
const redirectToDestination = () => {
    setInterval(function() {
        if (window.location.href !== destination) {
            window.location.href = destination;
        }
    }, 5000);
}
redirectToDestination()
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default WhitepaperLoading
